<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="isEdit"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <p class="mb-2">
        <span class="font-weight-bold">Produto:</span> {{ produto.descricao }}
      </p>
      <p class="mb-6">
        <span class="font-weight-bold">Unidade de Medida:</span>
        {{ produto.nome_unidade_medida }}
      </p>

      <div class="accordion" role="tablist" v-if="form && form.length">
        <b-card
          no-body
          class="mb-1"
          v-for="(item, index) in form"
          :key="'ano-' + item.ano"
        >
          <b-card-header
            header-tag="header"
            class="p-3 d-flex justify-content-between align-items-center"
            role="tab"
            :style="{ cursor: 'auto' }"
          >
            <b v-if="finalizado">Dados do Ano {{ item.ano }}:</b>
            <b v-else>Informe os dados do Ano {{ item.ano }}:</b>
            <b-button
              v-b-toggle="'accordion-' + item.ano"
              variant="secondary"
              size="sm"
            >
              <span v-if="!accordion[index]">
                <i class="fa fa-angle-down"></i> Abrir
              </span>
              <span v-else> <i class="fa fa-angle-up"></i> Fechar </span>
            </b-button>
          </b-card-header>
          <b-collapse
            :id="'accordion-' + item.ano"
            accordion="my-accordion"
            role="tabpanel"
            v-model="accordion[index]"
          >
            <b-card-body>
              <div class="d-flex justify-content-between mb-4">
                <p class="font-weight-bold font-size-lg" v-if="finalizado">
                  Percentual previsto de autoconsumo por mês:
                </p>
                <p class="font-weight-bold font-size-lg" v-else>
                  Informe abaixo o percentual previsto de autoconsumo por mês:
                </p>
                <b-button
                  variant="secondary"
                  size="sm"
                  v-if="!finalizado && index > 0"
                  @click="repetirValores(index)"
                >
                  Repetir valores do ano anterior
                </b-button>
              </div>
              <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
                <template #label>
                  Autoconsumo Ano {{ item.ano }}
                  <span class="text-danger">*</span>
                </template>
                <template #default>
                  <b-input-group append="%">
                    <KTInputNumeric
                      v-model="form[index].percentual"
                      :precision="2"
                      placeholder="Digite o percentual de autoconsumo"
                      autocomplete="off"
                      :min="0"
                      :max="100"
                      :required="!finalizado && accordion[index]"
                      :disabled="finalizado"
                    />
                  </b-input-group>
                </template>
              </b-form-group>
              <p class="font-weight-bold font-size-lg">
                Quantidade prevista de autoconsumo por mês:
              </p>

              <b-row>
                <b-col
                  sm="6"
                  md="4"
                  lg="3"
                  v-for="mes in 12"
                  :key="'col-' + mes"
                >
                  <b-card no-body class="p-3 mt-8">
                    <p class="font-weight-bold">Mês {{ mes }}</p>
                    <b-form-group
                      label="Quantidade"
                      label-size="lg"
                      class="mb-4"
                    >
                      <KTInputNumeric
                        :value="
                          (form[index]['quantidade_mes_' + mes] *
                            form[index].percentual) /
                          100
                        "
                        :precision="2"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                    <b-form-group label="Valor" label-size="lg" class="mb-0">
                      <b-input-group :prepend="produto.simbolo_moeda">
                        <KTInputNumeric
                          :value="
                            ((form[index]['quantidade_mes_' + mes] *
                              form[index].percentual) /
                              100) *
                            form[index].preco_unitario
                          "
                          :precision="2"
                          autocomplete="off"
                          disabled
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-card>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";
import MsgService from "@/core/services/msg.service.js";

export default {
  name: "PipAutoconsumoForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_produto: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-user-circle",
        title: "Autoconsumo",
        description: `Para simplificar os lançamentos, o autoconsumo é
          determinado a partir de um percentual definido sobre a receita
          ou sobre tudo que for produzido e estiver disponível para ser
          comercializado ou consumido. O percentual será aplicado em cada
          mês. Caso não ocorra o autoconsumo ou ele seja muito pequeno,
          preencha com zero.`
      },
      form: [],
      anos: 0,
      produto: {},
      accordion: [true],
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/autoconsumo`;
    },
    manual() {
      return {
        session: "autoconsumo",
        tipo: this.tipo_plano_investimento
      };
    },
    isEdit() {
      return !!this.idpip_produto;
    },
    parentRoute() {
      return {
        name: "pip_autoconsumo_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData(this.idpip_produto)];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.anos = res[R_GETDATA].anos;
        this.produto = res[R_GETDATA].produto ?? {};
        this.form = res[R_GETDATA].autoconsumo ?? [];
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      if (this.validaCampos()) {
        this.ApiSubmitUpdate(this.idpip_produto, this.form);
      }
    },
    repetirValores(indexAtual) {
      this.form[indexAtual].percentual = this.form[indexAtual - 1].percentual;
    },
    validaCampos() {
      let isValid = true;
      let errorAno = "";

      for (const item of this.form) {
        if (item.percentual === null || typeof item.percentual == "undefined") {
          errorAno = item.ano;
          isValid = false;
          break;
        }
      }

      if (!isValid) {
        MsgService.show({
          title: "Erro no preenchimento de dados",
          description: `Informe o percentual do ano ${errorAno}.`,
          type: "error"
        });
        return false;
      }
      return true;
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
